// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import VowGenerator from '../src/components/VowGenerator.vue';
import UserLogin from '../src/components/UserLogin.vue';
import UserRegister from '../src/components/UserRegister.vue';

const routes = [
  {
    path: '/',
    name: 'VowGenerator',
    component: VowGenerator,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: UserLogin,
  },
  {
    path: '/register',
    name: 'Register',
    component: UserRegister,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guards
router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('user');
  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
