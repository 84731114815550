<template>
  <div id="app">
    <v-app>
      <v-app-bar app color="primary" dark>
        <v-toolbar-title>VowPal</v-toolbar-title>
        <v-spacer></v-spacer>
        <div v-if="!isLoggedIn">
          <v-btn text @click="$router.push('/login')">Login</v-btn>
          <v-btn text @click="$router.push('/register')">Register</v-btn>
        </div>
        <div v-else>
          <v-btn text @click="logout">Logout</v-btn>
        </div>
      </v-app-bar>

      <v-main class="background-image">
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: false,
    };
  },
  created() {
    this.isLoggedIn = !!localStorage.getItem('user');
  },
  methods: {
    logout() {
      this.$axios.post('/logout')
        .then(() => {
          localStorage.removeItem('user');
          this.isLoggedIn = false;
          this.$router.push('/login');
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>

<style>
html, body, #app, .v-application {
  height: 100%;
  margin: 0;
  padding: 0;
}

.background-image {
  background-image: url('@/assets/square.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}


</style>