<!-- src/components/UserLogin.vue -->
<template>
  <v-container>
    <h2>Login</h2>
    <v-form @submit.prevent="login">
      <v-text-field
        v-model="username"
        label="Username"
        required
      ></v-text-field>
      <v-text-field
        v-model="password"
        label="Password"
        type="password"
        required
      ></v-text-field>
      <v-btn type="submit" color="primary">Login</v-btn>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    login() {
      this.$axios.post('/login', {
        username: this.username,
        password: this.password
      })
      .then(() => {
        localStorage.setItem('user', this.username);
        this.$router.push('/');
      })
      .catch(error => {
        console.error(error);
        alert('Invalid credentials');
      });
    }
  }
}
</script>
