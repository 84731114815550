<!-- src/components/UserRegister.vue -->
<template>
  <v-container>
    <h2>Register</h2>
    <v-form @submit.prevent="register">
      <v-text-field
        v-model="username"
        label="Username"
        required
      ></v-text-field>
      <v-text-field
        v-model="password"
        label="Password"
        type="password"
        required
      ></v-text-field>
      <v-btn type="submit" color="primary">Register</v-btn>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    register() {
      this.$axios.post('/register', {
        username: this.username,
        password: this.password
      })
      .then(() => {
        localStorage.setItem('user', this.username);
        this.$router.push('/');
      })
      .catch(error => {
        console.error(error);
        alert('Username already exists');
      });
    }
  }
}
</script>
