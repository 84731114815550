<template>
  <v-container class="vow-generator-content">
    <v-row>
      <!-- Single Column: Full Width for Input Sections and Generated Vow -->
      <v-col cols="12">
        <!-- Partner's Name -->
        <v-text-field
          v-model="partnerName"
          label="Partner's Name"
          required
          outlined
        ></v-text-field>

        <!-- Story of How We Met -->
        <v-textarea
          v-model="story"
          label="Story of how we met"
          rows="4"
          required
          outlined
          class="mt-4"
        ></v-textarea>

        <!-- Shareable Moments -->
        <div class="mt-4">
          <h3>Shareable Moments</h3>
          <div
            v-for="(moment, index) in moments"
            :key="index"
            class="moment-input d-flex align-center mt-2"
          >
            <v-text-field
              v-model="moments[index]"
              label="Moment to share..."
              required
              outlined
              class="flex-grow-1"
            ></v-text-field>
            <v-btn
              icon
              @click="addMoment"
              v-if="index === moments.length - 1"
              class="ml-2"
              color="primary"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="removeMoment(index)"
              v-if="moments.length > 1"
              class="ml-2"
              color="error"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Vows -->
        <div class="mt-6">
          <h3>Your Vows</h3>
          <div
            v-for="(vow, index) in vows"
            :key="index"
            class="vow-input d-flex align-center mt-2"
          >
            <v-text-field
              v-model="vows[index]"
              label="I vow to..."
              required
              outlined
              class="flex-grow-1"
            ></v-text-field>
            <v-btn
              icon
              @click="addVow"
              v-if="index === vows.length - 1"
              class="ml-2"
              color="primary"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="removeVow(index)"
              v-if="vows.length > 1"
              class="ml-2"
              color="error"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Themes -->
        <div class="mt-6">
          <h3>Select Themes</h3>
          <v-checkbox 
            v-for="theme in availableThemes"
            :key="theme"
            :label="theme"
            :value="theme"
            v-model="themes"
            hide-details
            class="mr-4 mt-2 dark-label"
          ></v-checkbox>
        </div>

        <!-- Length of Vows Dropdown -->
        <div class="mt-6">
          <h3>Vow Length</h3>
          <v-select
            v-model="vowLength"
            :items="['Short', 'Medium', 'Long']"
            label="Select Length"
            required
            outlined
          ></v-select>
        </div>

        <!-- Generate Vow Button with Loading Spinner -->
        <div class="mt-6">
          <v-btn color="primary" @click="generateVow" :disabled="isLoading">
            <v-progress-circular
              v-if="isLoading"
              indeterminate
              color="white"
              size="20"
              class="mr-2"
            ></v-progress-circular>
            <span v-else>Generate Vow</span>
          </v-btn>
        </div>

        <!-- Generated Vow Display Beneath -->
        <div v-if="generatedVow" class="generated-vow mt-8">
          <h3>Your Generated Vow</h3>
          <v-card class="transparent-card" flat>
            <!-- Loop through each paragraph and display as <p> -->
            <v-card-text>
              <p
                v-for="(paragraph, index) in splitIntoParagraphs(generatedVow)"
                :key="index"
              >
                {{ paragraph }}
              </p>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'VowGenerator',
  data() {
    return {
      partnerName: '',
      story: '',
      moments: [''],
      vows: [''],
      themes: [],
      vowLength: '', // Added vow length
      availableThemes: [
        'Serious',
        'Funny',
        'Beautiful',
        'Endearing',
        'Spiritual',
        'Christian',
        'Jewish',
        'Astrology',
        'Desperate',
        'Pirate',
        'Old English',
        'Romantic'
      ],
      generatedVow: '',
      isLoading: false, // New loading property
    };
  },
  methods: {
    addMoment() {
      this.moments.push('');
    },
    removeMoment(index) {
      this.moments.splice(index, 1);
    },
    addVow() {
      this.vows.push('');
    },
    removeVow(index) {
      this.vows.splice(index, 1);
    },
    generateVow() {
      // Translate length to GPT timing
      const vowLengthMap = {
        Short: 'no more than 200 words',
        Medium: 'no more than 400 words',
        Long: 'no more than 600 words'
      };

      // Basic validation
      if (!this.partnerName.trim() || !this.story.trim() || !this.vowLength) {
        alert('Please fill in all required fields.');
        return;
      }

      this.isLoading = true; // Start loading
      this.$axios
        .post('/generate_vow', {
          partnerName: this.partnerName,
          story: this.story,
          moments: this.moments,
          vows: this.vows,
          themes: this.themes,
          length: vowLengthMap[this.vowLength] // Pass length to the backend
        })
        .then(response => {
          this.generatedVow = response.data.generated_vow;
        })
        .catch(error => {
          console.error(error);
          alert('An error occurred while generating the vow.');
        })
        .finally(() => {
          this.isLoading = false; // Stop loading
        });
    },
    splitIntoParagraphs(vow) {
      // Split vow by double newlines (paragraph breaks)
      return vow.split(/\n\s*\n/);
    }
  }
};
</script>

<style scoped>
.vow-generator-content {
  padding: 20px;
}

.generated-vow {
  font-family: 'Playpen Sans', cursive;
  font-weight: 400;
  font-optical-sizing: auto;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.6); /* Semi-transparent white background */
  padding: 20px;
  border-radius: 8px;
}

.generated-vow p {
  margin-bottom: 15px;
  font-size: 18px;
}

.vow-title {
  font-family: 'Playpen Sans', cursive;
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .generated-vow {
    font-size: 16px;
  }

  .vow-title {
    font-size: 24px;
  }

  .moment-input,
  .vow-input {
    margin-bottom: 10px;
  }
}


.dark-label .v-label{
  opacity: 0;
}

/* Ensure all text is readable */
.vow-generator-content {
  color: #333; /* Dark text for light backgrounds */
}

.vow-generator-content h3 {
  color: #000; /* Dark headings */
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.ml-2 {
  margin-left: 8px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-8 {
  margin-top: 32px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 16px;
}
</style>
