// src/main.js
import { createApp } from 'vue'; // Use Vue 3's createApp
import App from './App.vue';
import router from '../router';
import axios from 'axios';
import 'vuetify/styles'; // Import Vuetify styles for Vue 3
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css'; // Ensure Material Design Icons are imported

// Axios Configuration
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://divercitynews.com'; // Ensure this matches your Flask backend URL

// Create Vuetify instance with a custom theme
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light', // Assuming you're using the light theme
    themes: {
      light: {
        colors: {
          primary: '#ee9cef', // Set your custom primary color here (yellow as an example)
          secondary: '#03A9F4', // You can customize other colors if needed
        },
      },
    },
  },
});

// Create Vue app instance
const app = createApp(App);

// Make Axios available globally
app.config.globalProperties.$axios = axios;

// Use router and vuetify in the Vue app
app.use(router);
app.use(vuetify);

// Mount the app
app.mount('#app');
